import {api_address_server} from "../conf";
import axios from 'axios';

export async function getInfo(){
    try {
        const response = await axios.get(api_address_server + 'get_info');
    
        // Проверяем статус ответа Axios
        if (response.status !== 200) {
          throw new Error(`Failed to fetch: ${response.status}`);
        }
    
        // Возвращаем данные из ответа
        return response.data;
      } catch (error) {
        // Обработка ошибок
        console.error('Error fetching data:', error.message);
        throw error;
      }
    
}

export async function getCatalog() {
    try {
      const response = await axios.get(api_address_server + 'get_company');
  
      // Проверяем статус ответа Axios
      if (response.status !== 200) {
        throw new Error(`Failed to fetch: ${response.status}`);
      }
  
      // Возвращаем данные из ответа
      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error('Error fetching catalog:', error.message);
      throw error;
    }
  }

  export async function getModels(id) {
    try {
      const url = id === 0 ? api_address_server + 'get_models?company' : api_address_server + 'get_models?company=' + (id ?? '').toString();
      const response = await axios.get(url);
      // Проверяем статус ответа Axios
      if (response.status !== 200) {
        throw new Error(`Failed to fetch: ${response.status}`);
      }
      // Возвращаем данные из ответа
      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error('Error fetching models:', error.message);
      throw error;
    }
  }

  export async function getModel(id) {
    try {
      const url = api_address_server + 'get_model?id=' + id.toString();
      const response = await axios.get(url);
  
      // Проверяем статус ответа Axios
      if (response.status !== 200) {
        throw new Error(`Failed to fetch: ${response.status}`);
      }
  
      // Возвращаем данные из ответа
      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error('Error fetching model:', error.message);
      throw error;
    }
  }
