import React, {useEffect, useState} from "react";
import styles from "./ShortCatalog.module.css";
import ex from "../../../img/brandExample.png";
import {getCatalog} from "../../../utils/api";
import {api_address_server} from "../../../conf";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectCompany, setCurrentPage } from '../../../store/actions/catalogActions';
import {Link } from 'react-router-dom'; 

export const ShortCatalog = () => {  
  const [isNavigated, setIsNavigated] = useState(false); 
  const [brands, setBrands] = useState([]); 

  
  const handleBrandClick = () => {
    setIsNavigated(true);
  };

  useEffect(()=>{
      const fetchBrands = async () => {
          const res = await getCatalog();
          if(res.status === 'OK'){
              setBrands(res.result)
          }
      }
      fetchBrands();
  },[]);

  useEffect(() => {
    const handlePopstate = () => {
      // Проверяем, если путь стал "/",
      // то значит пользователь вернулся на главный компонент
      if (window.location.pathname === "/") {
        setIsNavigated(false); // Показываем ShortCatalog снова
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  if (isNavigated) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <label className={styles.labelWrapepr}>
        <a className={styles.title} href="/catalog">
            <span className={styles.title2}></span>КАТАЛОГ
        </a>
      </label>
      <p className={styles.description}>
        В нашем каталоге представлено {brands.length} марок автомобилей в разных
        ценовых категориях
      </p>
      <div className={styles.brandsWrapper}>
        {brands.map((brand) => {
          return (
            <Link
            className={styles.imageLink}
            to={`/catalog?brand=${brand.id}&page=1`}
            key={brand.id}
            onClick={() => handleBrandClick()}
          >
            <img
              className={styles.image}
              src={api_address_server + brand.logo}
              alt={brand.name}
            />
          </Link>
          );
        })}
      </div>
    </div>
  );
};

//window.location.href='/catalog?brand='+brand.id