import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import styles from "./Catalog.module.css";
import { CommonCard } from "../../common/CommonCard/CommonCard";
import MediaQuery from "react-responsive";
import {fetchCompanies} from '../../../store/actions/companiesActions';
import { setSelectCompany, setCurrentPage } from '../../../store/actions/catalogActions';
import {fetchCars} from '../../../store/actions/carsActions';

export const Catalog = () => {
  const dispatch = useDispatch();
  const selectCompany = useSelector(state => state.catalog.selectCompany);
  const [nameCompany, setNameCompany] = useState('')
  const currentPage = useSelector(state => state.catalog.currentPage);
  const companies = useSelector(state => state.companies.companies);
  const allCars = useSelector(state => {
    if (selectCompany === 0) {
      return state.cars.cars;
    } else {
      return state.cars.cars.filter(car => car.company_id === selectCompany);
    }
  });
  const pageSize = 9; 
  const indexOfLastCar = currentPage * pageSize;
  const indexOfFirstCar = indexOfLastCar - pageSize;
  const cars = allCars.slice(indexOfFirstCar, indexOfLastCar);
  const totalPages = Math.ceil(allCars.length / pageSize);

  const [openMenu, setOpenMenu] = useState(false)
  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchCars());   
  }, [dispatch]);

  useEffect(()=>{
    if(companies !== undefined && companies.length > 0){
      const selectedCompany = companies.find(company => company.id === selectCompany);
      if (selectedCompany) {
        setNameCompany(selectedCompany.name);
      }
    }
  },[selectCompany, companies]);

  useEffect(() => {
    // Проверяем localStorage или URL-параметры на наличие информации о текущей странице
    const savedBrand = localStorage.getItem('brand') || new URLSearchParams(window.location.search).get('brand');
    if (savedBrand){
      dispatch(setSelectCompany(parseInt(savedBrand)));
    }
    const savedPage = localStorage.getItem('currentPage') || new URLSearchParams(window.location.search).get('page');
    const page = savedPage ? parseInt(savedPage) : 1;
    dispatch(setCurrentPage(page));
    
    // Устанавливаем текущую страницу при загрузке компонента
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleBrandClick = (companyId) => {
    dispatch(setSelectCompany(companyId));
    dispatch(setCurrentPage(1));
    // Обновляем URL
    window.history.pushState({}, null, `/catalog${companyId === 0 ? '' : `?brand=${companyId}`}`);
    setOpenMenu(false);
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
    localStorage.setItem('currentPage', pageNumber);
    window.history.pushState({}, null, `/catalog${selectCompany === 0 ? '' : `?brand=${selectCompany}`}${selectCompany === 0 ? '?' : '&'}page=${pageNumber}`);
  };

  const handlePopstate = (dispatch) => {
    const savedPage = localStorage.getItem('currentPage') || new URLSearchParams(window.location.search).get('page');
    const page = savedPage ? parseInt(savedPage) : 1;
    dispatch(setCurrentPage(page));
  };

  return (
      <>
        <div className={styles.headBlock}><a className={styles.head} href={'/'}>Главная</a><div className={styles.head2}><span className={styles.head2_2}></span>Каталог</div></div>
        <div className={styles.mainWrapper}>
          <p className={styles.title}>КАТАЛОГ</p>
          <MediaQuery maxWidth={1100}>
            <p className={styles.brandsTitle} style={{cursor:"pointer"}} onClick={()=>setOpenMenu(true)}>
              <span className={styles.contactTitle2}></span>Производитель <span className={styles.contactTitle3}>{nameCompany}</span>
            </p>
          </MediaQuery>
          <div className={styles.wrapperBlock}>
            <div className={styles.wrapper}>
              <MediaQuery minWidth={1101}>
                <div className={styles.brandsWrapper}>
                  <p className={styles.brandsTitle}>
                    <span className={styles.contactTitle2}></span>
                    Производитель
                  </p>
                  <div className={styles.brands}>
                    <div 
                      className={styles.brand}
                      style={selectCompany === 0 ? {color:'#ac0a08'} : {}}
                      onClick={() => handleBrandClick(0)}
                    >
                      <span className={styles.brandSpan} style={selectCompany === 0 ? {borderLeft: '16px solid #ac0a08'} : {}}></span>
                      Все
                    </div>
                    {companies.length > 0 && companies.map((item)=>
                        <div 
                          className={styles.brand}
                          style={selectCompany === item.id ? {color:'#ac0a08'} : {}}
                          key={item.id}
                          onClick={() => handleBrandClick(item.id)}
                        >
                          <span className={styles.brandSpan} style={selectCompany === item.id ? {borderLeft: '16px solid #ac0a08'} : {}}></span>
                          {item.name}
                        </div>)}
                  </div>
                </div>
              </MediaQuery>
                <div className={styles.itemsWrapper}>
                  {cars.length > 0 && cars.map((item)=>
                      <CommonCard key={item.id} data={item}/>)}
                    {/* Пагинация */}
                </div> 
            </div> 
          </div>
              {totalPages > 1 && (
                <div className={styles.pagination}>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      className={currentPage === index + 1 ? styles.activePage : ''}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              )}    
        </div>
        {openMenu && <div className={styles.brandsWrapperFon} onClick={()=>setOpenMenu(false)}>
          <div className={styles.brandsWrapper} onClick={e=>e.stopPropagation()}>
            <p className={styles.brandsTitle}>
              <span className={styles.contactTitle2}></span>
              Производитель
            </p>
            <div className={styles.brands}>
              <div 
                className={styles.brand}
                style={selectCompany === 0 ? {color:'#ac0a08'} : {}}
                onClick={() => handleBrandClick(0)}
              >
                <span className={styles.brandSpan} style={selectCompany === 0 ? {borderLeft: '16px solid #ac0a08'} : {}}></span>
                Все
              </div>
              {companies.length > 0 && companies.map((item)=>
                  <div 
                    className={styles.brand}
                    style={selectCompany === item.id ? {color:'#ac0a08'} : {}}
                    key={item.id}
                    onClick={() => handleBrandClick(item.id)}
                  >
                    <span className={styles.brandSpan} style={selectCompany === item.id ? {borderLeft: '16px solid #ac0a08'} : {}}></span>
                    {item.name}
                  </div>)}
            </div>
          </div>
        </div>}
      </>
  );
};
