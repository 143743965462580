import React, {useEffect, useState} from "react";
import styles from "./Contacts.module.css";
import {getInfo} from "../../../utils/api";

export const Contacts = () => {
  const [SocialsLinks, setSocialsLinks] = useState([
    { description: "Instagram", link: "#" },
    { description: "E-mail", link: "#" },
    { description: "Telegram", link: "#" },
    { description: "YouTube", link: "#" },
  ]);
  useEffect(()=>{
    const getInfoT = async () => {
      const res = await getInfo();
      if(res.status === 'OK'){
        setSocialsLinks([
          { title: "INSTAGRAM",
            link: 'https://instagram.com/'+res.result.link_instagram,
            description: "Подпишись на наш профиль , новинки авто , обзоры , и просто интересные новости с мира авто" },
          { title: "E-MAIL",
            link: 'mailto:'+res.result.link_email,
            description: "Свои вопросы и предложения по сотрудничеству можно обсудить по почте",
          },
          { title: "TELEGRAM",
            link: 'https://t.me/'+res.result.link_telegram,
            description:
                "В нашем канале публикуем расчеты на автомобили, можно посмотреть какие машины уже нашли своих хозяев и почитать отзывы клиентов",
          },
          { title: "YOUTUBE",
            link: 'https://youtube.com/'+res.result.link_youtube,
            description: "Самые свежие обзоры на новые автомобили , видео материалы с автомобильных выставок и тест драйв наших авто , так же отзывы клиентов",
          }
        ])
      }
    }
    getInfoT();
  },[])

  const ContactElems = (title, link, key, description) => {
    return (
      <a className={styles.itemWrapper} key={key} href={link} target={'_blank'}>
        <label className={styles.linkLabel}>
          <div className={styles.link}>
            <span className={styles.contactTitle2}></span>
            <div className={styles.contactTitle}>{title}</div>
          </div>
        </label>
        <div className={styles.contactDescription}>{description}</div>
      </a>
    );
  };

  const Contacts = () => {
    return (
        <>
          <div className={styles.headBlock}><a className={styles.head} href={'/'}>Главная</a><div className={styles.head2}><span className={styles.head2_2}></span>Контакты</div></div>
          <div className={styles.wrapper}>
            <div className={styles.title}>КОНТАКТЫ</div>
            <div className={styles.description}>
              Если у вас есть вопрос, то обязательно пишите нам в любую из указанных
              социальных сетей и мы обязательно вам ответим
            </div>
            <div className={styles.contacts}>
              {SocialsLinks.map((ContactElem) => {
                return ContactElems(
                    ContactElem.title,
                    ContactElem.link,
                    ContactElem.description,
                    ContactElem.description
                );
              })}
            </div>
          </div>
        </>
    );
  };

  return <Contacts />;
};
