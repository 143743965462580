import axios from 'axios';
import { setCurrencyRates, setActiveCurrency, setLastUpdate  } from '../actions/currencyActions';

export const fetchCurrencyRates = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('https://open.er-api.com/v6/latest/CNY');
      const rates = response.data.rates;
      const keys = ['CNY', 'RUB', 'USD']; 
      const filteredRates = keys.reduce((result, key) => {
        // Проверяем, есть ли ключ в исходном объекте, прежде чем добавить его в новый объект
        if (rates.hasOwnProperty(key)) {
          result[key] = rates[key];
        }
        return result;
      }, {});
      dispatch(setCurrencyRates(filteredRates));
      localStorage.setItem('currencyRates', JSON.stringify(filteredRates));

      const currentTime = Date.now();
      dispatch(setLastUpdate(currentTime));
      localStorage.setItem('lastUpdate', currentTime.toString());

    } catch (error) {
      console.error('Error fetching currency rates:', error);
    }
  };
};