import React, {useEffect, useState} from "react";
import styles from "./Chanel.module.css";
import Button from "../button/button";
import {getInfo} from "../../../utils/api";
import {api_address_server} from "../../../conf";

export const Chanel = () => {
    const [youTube, setYouTube] = useState('');
    useEffect(()=>{
        const getInfoT = async () => {
            const res = await getInfo();
            if(res.status === 'OK'){
                setYouTube('https://youtube.com/'+res.result.link_youtube)
            }
        }
        getInfoT();
    },[])
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}><span className={styles.title2}></span>НАШ YOUTUBE-КАНАЛ</h2>
      <p className={styles.text}>
        Подписывайся, чтобы не пропустить свежие автообзоры, тест-драйвы,
        краш-тесты, полезные советы, идеи и мнения. Ведь вся жизнь игра и
        скорость тому виной!
      </p>
      <Button className={styles.buttonLink} onClick={()=>{window.open(youTube, '_blank')}}>
        Подписаться
      </Button>
    </div>
  );
};
