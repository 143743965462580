import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import store from './store/store';
import App from "./App";
import {fetchCurrencyRates} from './store/middleware/currencyMiddleware';
import './index.scss';

const checkAndUpdateCurrencyRatesPeriodically = () => {
    checkAndUpdateCurrencyRates(); // Вызываем функцию сразу
    setInterval(checkAndUpdateCurrencyRates, 4 * 60 * 60 * 1000); // Вызываем её каждые 4 часа
  };
const checkAndUpdateCurrencyRates = () => {
    const lastUpdate = store.getState().currency.lastUpdate;
    if (!lastUpdate || Date.now() - lastUpdate >= 4 * 60 * 60 * 1000) { // Проверяем, прошло ли 4 часа с последнего обновления
        store.dispatch(fetchCurrencyRates());
    }
};
  
checkAndUpdateCurrencyRatesPeriodically(); 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
    <App />
    </Provider>
);
