import { createStore, combineReducers, applyMiddleware } from 'redux';
import rootReducer from './reducers/index';
import {thunk} from 'redux-thunk';
import { setActiveCurrency, setCurrencyRates } from './actions/currencyActions';
import { fetchCurrencyRates } from './middleware/currencyMiddleware';
const savedActiveCurrency = localStorage.getItem('activeCurrency');
const savedCurrencyRates = localStorage.getItem('currencyRates');

let parsedCurrencyRates;
try {
  parsedCurrencyRates = JSON.parse(savedCurrencyRates);
} catch (error) {
  parsedCurrencyRates = null;
}

const initialState = {
    currency: {
      activeCurrency: savedActiveCurrency || 'CNY',
      rates: parsedCurrencyRates || null,
      lastUpdate: null,
    },
  };
const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

// Если в локальном хранилище не было сохраненной валюты, устанавливаем ее в store
if (!savedActiveCurrency) {
    store.dispatch(setActiveCurrency('CNY'));
  }
if (!parsedCurrencyRates) {
    store.dispatch(fetchCurrencyRates());
}


export default store;