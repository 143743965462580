import React, {useEffect, useState} from "react";
import styles from "./About.module.css";
import Button from "../../common/button/button";
import {getInfo} from "../../../utils/api";

export const About = () => {
    const [youTube, setYouTube] = useState('');
    useEffect(()=>{
        const getInfoT = async () => {
            const res = await getInfo();
            if(res.status === 'OK'){
                setYouTube('https://youtube.com/'+res.result.link_youtube)
            }
        }
        getInfoT();
    },[])
  return (
      <>
          <div className={styles.headBlock}><a className={styles.head} href={'/'}>Главная</a><div className={styles.head2}><span className={styles.head2_2}></span>О нас</div></div>
          <div className={styles.wrapper}>
              <div className={styles.title}>О НАС</div>
              <div className={styles.description}>
                  Мы - команда Premium Avtomir. Поможем вам купить автомобиль из разных стран: Китай, Америка, Япония, Корея и доставить в любой город.
              </div>
              <Button className={styles.button}  onClick={()=>{window.open(youTube, '_blank')}}>Подписаться</Button>
          </div>
      </>
  );
};
