export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES';
export const SET_ACTIVE_CURRENCY = 'SET_ACTIVE_CURRENCY';
export const setCurrencyRates = (rates) => ({
  type: SET_CURRENCY_RATES,
  payload: rates,
});
export const setActiveCurrency = (currency) => ({
  type: SET_ACTIVE_CURRENCY,
  payload: currency,
});
export const setLastUpdate = () => {
  return async (dispatch) => {
    const currentTime = new Date().getTime();
    localStorage.setItem('lastUpdate', currentTime);
  };
};