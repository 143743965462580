import React, {useEffect, useState} from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styles from "./Car.module.css";
import image from "./../../../img/cardExample.png";
import {getModel} from "../../../utils/api";
import {api_address_server} from "../../../conf";
import { fetchCurrencyRates } from '../../../store/middleware/currencyMiddleware';
import {formatNumber} from '../../../utils/formatNumber';

export const Car = () => {
  const dispatch = useDispatch();
  const [car, setCar] = useState({})
  const [image, setImage] = useState([])
  const [numberImage, setNumberImage] = useState(0)
  const exchangeRates = useSelector(state => state.currency.rates);
  const activeCurrency = useSelector((state) => state.currency.activeCurrency);
  const [activeCurrencyPrice, setActiveCurrencyPrice] = useState(null);
  const currency = new Map();
  currency.set('RUB', '₽');
  currency.set('USD', '$');
  currency.set('CNY', '¥');

  useEffect(() => {
    if(exchangeRates.USD === undefined){
      console.log('call from car');
      dispatch(fetchCurrencyRates());
    }
    const usdPrice = Number((car.price * exchangeRates.USD).toFixed(2));
    const rubPrice = Number((car.price * exchangeRates.RUB).toFixed(2));
    let priceInActiveCurrency;
    switch (activeCurrency) {
      case 'USD':
        priceInActiveCurrency = usdPrice;
        break;
      case 'RUB':
        priceInActiveCurrency = rubPrice;
        break;
      case 'CNY':
        priceInActiveCurrency = car.price;
        break;
      default:
        priceInActiveCurrency = car.price;
        break;
    }
    setActiveCurrencyPrice(priceInActiveCurrency);
    
  }, [activeCurrency,exchangeRates,car.price]);

  useEffect(()=>{
    const getModelT = async () => {
      const res = await getModel(window.location.search.split('=')[1]);
      if(res.status === 'OK'){
        setCar(res.result[0])
        let temp = []
        temp.push(res.result[0].photo1)
        temp.push(res.result[0].photo2)
        temp.push(res.result[0].photo3)
        temp.push(res.result[0].photo4)
        temp.push(res.result[0].photo5)
        temp.push(res.result[0].photo6)
        temp.push(res.result[0].photo7)
        temp.push(res.result[0].photo8)
        temp.push(res.result[0].photo9)
        temp.push(res.result[0].photo10)
        temp = temp.filter(item => item !== '')
        setImage(temp)
      }
    }
    getModelT();
  },[dispatch, exchangeRates])

  function nextImage(){
    setNumberImage((numberImage + 1) % image.length);
  }
  function nextImage2(){
    setNumberImage((numberImage - 1 + image.length) % image.length);
  }

  return (
      <>
        <div className={styles.headBlock}><a className={styles.head} href={'/'}>Главная</a><a className={styles.head} href={'/catalog'}><span className={styles.head2_2}></span>Каталог</a><div className={styles.head2}><span className={styles.head2_2}></span>{car.name}</div></div>
        <div className={styles.wrapper}>
          <div className={styles.commom}>
            <div className={styles.imageWrapper}>
              <div className={styles.imageBlock}>
                <img src={api_address_server+image[numberImage]} className={styles.image} />
              </div>
              <div className={styles.nextBlock}>
                  <div className={styles.nextImage2} onClick={nextImage2}>
                    <div className={styles.nextImageB}></div>
                  </div>
                  <div className={styles.nextImage} onClick={nextImage}>
                    <div className={styles.nextImageB}></div>
                  </div>
                </div>
            </div>
            <div className={styles.infoCommon}>
              <div>
                <div className={styles.model}>{car.name}</div>
                <div className={styles.infoTitle}><span className={styles.title2}></span>ХАРАКТЕРИСТИКИ</div>
                <div className={styles.shortInfo}>
                  <div className={styles.shortInfoItem}>Размеры: {car.size}</div>
                  <div className={styles.shortInfoItem}>Колесная база: {car.wheelbase}</div>
                  <div className={styles.shortInfoItem}>Колеса: {car.wheels}</div>
                  <div className={styles.shortInfoItem}>Батарея: {car.battery}</div>
                  <div className={styles.shortInfoItem}>Мощность: {car.power}</div>
                  <div className={styles.shortInfoItem}>Разгон: {car.acceleration}</div>
                  <div className={styles.shortInfoItem}>Максимальная скорость: {car.speed}</div>
                  <div className={styles.shortInfoItem}>
                    Дальность хода по циклу NEDC: {car.range}
                  </div>
                </div>
              </div>
              <div className={styles.cost}>
                Цена: {activeCurrencyPrice ? formatNumber(activeCurrencyPrice) : ''} {currency.get(activeCurrency)}
              </div>
            </div>
          </div>
          <div className={styles.shared}>
            <div className={styles.infoTitle}><span className={styles.title2}></span>ИНФОРМАЦИЯ О ПОКУПКЕ</div>
            <div className={styles.sharedInfo}>
              <div className={styles.sharedInfoText}>
                {car.info}
              </div>
            </div>
          </div>
        </div>
      </>
  );
};
