import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import styles from "./CommonCard.module.css";
import example from "./../../../img/example.png";
import {api_address_server} from "../../../conf";
import { fetchCurrencyRates } from '../../../store/middleware/currencyMiddleware';
import {formatNumber} from '../../../utils/formatNumber';

export const CommonCard = ({data}) => {
  const dispatch = useDispatch();
  const activeCurrency = useSelector((state) => state.currency.activeCurrency);
  const exchangeRates = useSelector((state) => state.currency.rates);
  const currency = new Map();
  currency.set('RUB', '₽');
  currency.set('USD', '$');
  currency.set('CNY', '¥');

  const [activeCurrencyPrice, setActiveCurrencyPrice] = useState(null);

  useEffect(() => {
    if(exchangeRates.USD === undefined){
      console.log('call from common');
      dispatch(fetchCurrencyRates());
    }
    const usdPrice = Number((data.price * exchangeRates.USD).toFixed(0));
    const rubPrice = Number((data.price * exchangeRates.RUB).toFixed(0));
    let priceInActiveCurrency;
    switch (activeCurrency) {
      case 'USD':
        priceInActiveCurrency = usdPrice;
        break;
      case 'RUB':
        priceInActiveCurrency = rubPrice;
        break;
      case 'CNY':
        priceInActiveCurrency = data.price;
        break;
      default:
        priceInActiveCurrency = data.price;
        break;
    }
    setActiveCurrencyPrice(priceInActiveCurrency)
  }, [data.price, data.currency, exchangeRates, activeCurrency]);

  return (
    <a href={"/car?id="+data.id} className={styles.wrapper}>
      <div className={styles.imageBlock}>
        <img src={api_address_server+data.photo1} className={styles.image} />
      </div>
      <p className={styles.model}>{data.name}</p>
      <div className={styles.parameters}>
        <div className={styles.info}>
          <div className={styles.params}>
            <div className={styles.param}>
              <div className={styles.paramTitle}>Размер: {data.size}</div>
            </div>
            <div className={styles.param}>
              <div className={styles.paramTitle}>Колеса: {data.wheels}</div>
            </div>
            <div className={styles.param}>
              <div className={styles.paramTitle}>Мощность: {data.power}</div>
            </div>
          </div>
          <div className={styles.costParam}>Цена: {activeCurrencyPrice ? formatNumber(activeCurrencyPrice) : ''} {currency.get(activeCurrency)}</div>
        </div>
      </div>
    </a>
  );
};
