import  {FETCH_CARS_REQUEST, FETCH_CARS_SUCCESS, FETCH_CARS_FAILURE, SORT_CARS_ALPHABETICALLY} from '../actions/carsActions';
const initialState = {
  cars: [],
  loading: false,
  error: null,
};

const carsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CARS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_CARS_SUCCESS:
        return {
          ...state,
          cars: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_CARS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case SORT_CARS_ALPHABETICALLY:
        return {
          ...state,
          cars: [...state.cars.sort((a, b) => a.name.localeCompare(b.name))],
        };
      default:
        return state;
    }
  };

export default carsReducer;