import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCurrency } from '../../../store/actions/currencyActions';
import styles from "./Header.module.css";
import { ReactComponent as Logo } from "../../../img/Logo.svg";
import MediaQuery from "react-responsive";

export const Header = () => {
  const dispatch = useDispatch();
  const activeCurrency = useSelector(state => state.currency.activeCurrency);
  
  useEffect(() => {
    const storedCurrency = localStorage.getItem('activeCurrency');
    if (storedCurrency) {
      // Если в localStorage есть значение, устанавливаем его
      dispatch(setActiveCurrency(storedCurrency));
      console.log('Active currency set from localStorage:', storedCurrency)
    }
  }, [dispatch]);
 
  const handleCurrencyClick = (currency) => {
    // Устанавливаем активную валюту при клике на значок
    dispatch(setActiveCurrency(currency));
    // Сохраняем activeCurrency в локальное хранилище
    localStorage.setItem('activeCurrency', currency);
  };
  const NavLink = (text, link, key) => {
    return (
      <a className={styles.navLink} href={link} key={key}>
        {text}
      </a>
    );
  };
    const NavLink2 = (text, link, key) => {
        return (
            <a className={styles.navLink2} href={link} key={key}>
                {text}
            </a>
        );
    };

  const NavElems = [
    { description: "Главная", link: "/" },
    { description: "О нас", link: "/about" },
    { description: "Авто", link: "/catalog" },
    { description: "Контакты", link: "/contacts" },
    {description: "Отзывы", link: "/cases"},
  ];
  const [openMenu, setOpenMenu] = useState(false)
  return (
      <>
        <div className={styles.wrapper}>
            <MediaQuery maxWidth={799}>
                <div className={styles.menuBlock} onClick={()=>{setOpenMenu(!openMenu)}}>
                    <div className={styles.menuBlockItem}></div>
                    <div className={styles.menuBlockItem}></div>
                    <div className={styles.menuBlockItem}></div>
                </div>
            </MediaQuery>
            <a href="/">
                <Logo />
            </a>
            <div  className={styles.rightPart}>
                <div className={styles.currencyIcons}>
                    <button className={`${styles.currencyIcon} ${activeCurrency === 'RUB' ? styles.activeCurrency : ''}`}
                    onClick={() => handleCurrencyClick('RUB')}>₽</button>
                    <button className={`${styles.currencyIcon} ${activeCurrency === 'USD' ? styles.activeCurrency : ''}`}
                    onClick={() => handleCurrencyClick('USD')}>$</button>
                    <button className={`${styles.currencyIcon} ${activeCurrency === 'CNY' ? styles.activeCurrency : ''}`}
                    onClick={() => handleCurrencyClick('CNY')}>¥</button>
                </div>
                <MediaQuery minWidth={800}>
                    <nav>
                        {NavElems.map((NavElem) =>
                            NavLink(NavElem.description, NavElem.link, NavElem.description)
                        )}
                    </nav>
                </MediaQuery>
            </div>
        </div>
          {openMenu && <div>
              <div className={styles.menuFon} onClick={()=>{setOpenMenu(!openMenu)}}></div>
              <div className={styles.menuBlock2} onClick={()=>{setOpenMenu(!openMenu)}}>
                  <div className={styles.menuBlockItem2}></div>
                  <div className={styles.menuBlockItem3}></div>
              </div>
              <div className={styles.menu}>
                  <div className={styles.menuText}><span className={styles.contactTitle2}></span>МЕНЮ</div>
                  {NavElems.map((NavElem) =>
                      NavLink2(NavElem.description, NavElem.link, NavElem.description)
                  )}
              </div>
          </div>}
      </>
  );
};
