import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./components/common/Footer/Footer";
import { Header } from "./components/common/Header/Header";
import "./App.css";
import { About } from "./components/pages/About/About";
import { Contacts } from "./components/pages/Contacts/Contacts";
import { Main } from "./components/pages/Main/Main";
import { Catalog } from "./components/pages/Catalog/Catalog";
import { ShortCatalog } from "./components/common/ShortCatalog/ShortCatalog";
import { Chanel } from "./components/common/Chanel/Chanel";
import { Car } from "./components/pages/Car/Car";
import Cases  from "./components/pages/Cases/Cases";
import { fetchCurrencyRates } from './store/middleware/currencyMiddleware';

import 'swiper/css';

function App () {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrencyRates());
  }, [dispatch]);
  
  return (
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/cases" element={<Cases/>}/>
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/car" element={<Car />} />
        </Routes>
        {document.location.pathname === "/catalog" ||
        document.location.pathname === "/car" ? null : (
          <ShortCatalog />
        )}
        {document.location.pathname === "/" ? <Chanel /> : null}
        <Footer />
      </BrowserRouter>
  );
};
export default App;