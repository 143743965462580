export const SET_SELECT_COMPANY = 'SET_SELECT_COMPANY';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const setSelectCompany = (selectCompany) => ({
    type: SET_SELECT_COMPANY,
    payload: selectCompany,
  });
  export const setCurrentPage = (page) => ({
    type: SET_CURRENT_PAGE,
    payload: page,
  });