import classes from "./Cases.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from "swiper/modules";
import React, { useState, useRef, useEffect } from 'react';
import ArrowLeftIcon from "../../common/ArrowLeftIcon/ArrowLeftIcon";
import ArrowRightIcon from "../../common/ArrowRightIcon/ArrowRightIcon";
import PauseIcon from "../../common/PleerIcons/PauseIcon";
import PlayIcon from "../../common/PleerIcons/PlayIcon";
import cases1 from "../../../img/videos/video_1.mp4";
import cases2 from "../../../img/videos/video_2.mp4";
import cases3 from "../../../img/videos/video_3.mp4";
import cases4 from "../../../img/videos/video_4.mp4";
import cases5 from "../../../img/videos/video_5.mp4";
import cases6 from "../../../img/videos/video_6.mp4";
import cases7 from "../../../img/videos/video_7.mp4";
import cases8 from "../../../img/videos/video_8.mp4";
import cases9 from "../../../img/videos/video_9.mp4";
import cases10 from "../../../img/videos/video_10.mp4";
import cases11 from "../../../img/videos/video_11.mp4";
import cases12 from "../../../img/videos/video_12.mp4";
import cases1_preview from "../../../img/videos/video_1_preview.jpg";
import cases2_preview from "../../../img/videos/video_2_preview.jpg";
import cases3_preview from "../../../img/videos/video_3_preview.jpg";
import cases4_preview from "../../../img/videos/video_4_preview.jpg";
import cases5_preview from "../../../img/videos/video_5_preview.jpg";
import cases6_preview from "../../../img/videos/video_6_preview.jpg";
import cases7_preview from "../../../img/videos/video_7_preview.jpg";
import cases8_preview from "../../../img/videos/video_8_preview.jpg";
import cases9_preview from "../../../img/videos/video_9_preview.jpg";
import cases10_preview from "../../../img/videos/video_10_preview.jpg";
import cases11_preview from "../../../img/videos/video_11_preview.jpg";
import cases12_preview from "../../../img/videos/video_12_preview.jpg";

const Cases = () => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
    const videoRefs = useRef([]);

    const handleTogglePlay = async (index) => {
        if (currentVideoIndex !== null && currentVideoIndex !== index) {
            const another = videoRefs.current[currentVideoIndex];
            await another.pause();
        }
        const video = videoRefs.current[index];
        if (video.paused) {
            await video.play();
            setCurrentVideoIndex(index);
        } else {
            await video.pause();
            setCurrentVideoIndex(null);
        }
    };


    const imageCases = [
        {id:1, image: cases1, preview: cases1_preview},
        {id:2, image: cases2, preview: cases2_preview},
        {id:3, image: cases3, preview: cases3_preview},
        {id:4, image: cases4, preview: cases4_preview},
        {id:5, image: cases5, preview: cases5_preview},
        {id:6, image: cases6, preview: cases6_preview},
        {id:7, image: cases7, preview: cases7_preview},
        {id:8, image: cases8, preview: cases8_preview},
        {id:9, image: cases9, preview: cases9_preview},
        {id:10, image: cases10, preview: cases10_preview},
        {id:11, image: cases11, preview: cases11_preview},
        {id:12, image: cases12, preview: cases12_preview},
    ];


    return (
        <div className={classes.Cases} id="portfolio">
            <h3 className={classes.heading}>
                Отзывы клиентов
            </h3>
            <Swiper
                modules={[Navigation]}
                loop={true}
                spaceBetween={20}
                slidesPerGroup={1}
                className={classes.swiperWrap}
                navigation={{ // Опции навигации
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1.4,
                        spaceBetween: 30
                    },

                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }}
            >
                {imageCases.map((item, index) =>
                    <SwiperSlide
                        className={classes.slide}
                        style={{backgroundSize: 'contain',
                                backgroundPosition: 'center'}}
                        key={item.id}
                        onClick={() => handleTogglePlay(index)}
                    >
                        <video
                            ref={ref => videoRefs.current[index] = ref}
                            controls 
                            playsInline 
                            className={classes.video}
                            poster={item.preview}
                            preload="auto"
                        >
                            <source src={item.image} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div 
                            className={classes.overlay}
                        >
                           {currentVideoIndex === index ? (
                                <PauseIcon className={classes.iconPlayer} />
                            ) : (
                                <PlayIcon className={classes.iconPlayer} />
                            )}
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
            <div className={classes.navigation}>
                <button className={`${classes.roundedBtn} swiper-button-prev`}>
                    <ArrowLeftIcon className={classes.icon}/>
                </button>
                <button className={`${classes.roundedBtn} swiper-button-next`}>
                    <ArrowRightIcon className={classes.icon}/>
                </button>
            </div>
        </div>
    );
}

export default Cases;
