import { combineReducers } from 'redux';
import currencyReducer from './currencyReducer';
import companiesReducer from './companiesReducer';
import carsReducer from './carsReducer';
import catalogReducer from './catalogReducer';

const rootReducer = combineReducers({
  currency: currencyReducer,
  companies: companiesReducer,
  cars: carsReducer,
  catalog: catalogReducer,
});

export default rootReducer;