import React, {useEffect, useState} from "react";
import styles from "./Main.module.css";
import fair1 from '../../../img/fair.png';
import fair2 from '../../../img/fair2.png';
import car1 from '../../../img/car1.png';
import man from '../../../img/mann.png';
import Button from "../../common/button/button";
import {getInfo} from "../../../utils/api";
import {api_address_server} from "../../../conf";
import MediaQuery from "react-responsive";
import Cases from "../Cases/Cases";

export const Main = () => {

  useEffect(() => {
    localStorage.setItem('currentPage', 1); // Сохранение в localStorage
  }, []);
  
  const [photo, setPhoto] = useState('');
  useEffect(()=>{
    const getInfoT = async () => {
      const res = await getInfo();
      if(res.status === 'OK'){
        setPhoto(api_address_server+res.result.photo)
      }
    }
    getInfoT();
  },[])
  const aboutUsDescriptionText = "Мы работаем с 2021 года. Привезли более 300 автомобилей  из разных стран. Выполним любой ваш запрос и доставим автомобиль в короткие сроки.";
  const timetableText = ["Время работы: 10:00 - 23:00 по Пекину.", "Без перерывов выходных."];
  return (
    <div className={styles.wrapper}>
      <div className={styles.block1}>
        <div className={styles.text1}>PREMIUM AVTOMIR</div>
        <div className={styles.text2}>Поможем купить и пригнать автомобиль из Китая, США, ОАЭ. Быстро, надежно, по лучшим ценам.</div>
        <Button onClick={()=>{window.location.href = '/catalog'}}>Смотреть авто</Button>
        <div className={styles.blockCar}>
            <img src={fair1} alt={''} className={styles.fair1}/>
            <img src={fair2} alt={''} className={styles.fair2}/>
            <img src={car1} alt={''} className={styles.car1}/>
        </div>
        <div className={styles.rect1}></div>
        <div className={styles.rect2}></div>
      </div>
      <MediaQuery minWidth={1025}>
        <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <div className={styles.infoDescription}>
            <p className={styles.contactTitle}>
              <span className={styles.contactTitle2}></span> О НАС
            </p>
            <p className={styles.infoDescriptionText}>
            {aboutUsDescriptionText}
            </p>
          </div>
          <div className={styles.infoDescription}>
            <div className={styles.contactTitle}><span className={styles.contactTitle2}></span>НАШ ГРАФИК</div>
            <p className={styles.infoDescriptionText}>
            {timetableText[0]}<br/>{timetableText[1]}
            </p>
          </div>
        </div>
        <div className={styles.visual}>
          <img className={styles.visualImg} src={man} alt="" />
          <div className={styles.visualBlock}>
            <Button className={styles.brandsButton} style={{width: '90%', maxHeight:'90%'}}  onClick={()=>{window.location.href = '/about'}}>Подробнее</Button>
          </div>

        </div>
      </div>
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <div className={styles.infoDescription}>
              <p className={styles.contactTitle}>
                <span className={styles.contactTitle2}></span> О НАС
              </p>
              <p className={styles.infoDescriptionText}>
              {aboutUsDescriptionText}
              </p>
            </div>
            <div className={styles.visual}>
              <img className={styles.visualImg} src={man} alt="" />
              <div className={styles.visualBlock}>
                <Button className={styles.brandsButton} style={{width: '90%', maxHeight:'90%'}}  onClick={()=>{window.location.href = '/about'}}>Подробнее</Button>
              </div>
            </div>
            <div className={styles.infoDescription}>
              <div className={styles.contactTitle}><span className={styles.contactTitle2}></span>НАШ ГРАФИК</div>
              <p className={styles.infoDescriptionText}>
              {timetableText[0]}<br/>{timetableText[1]}
              </p>
            </div>
          </div>
        </div>
      </MediaQuery>
      <Cases/>
    </div>
  );
};
