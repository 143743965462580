import {FETCH_COMPANIES_REQUEST, FETCH_COMPANIES_SUCCESS, FETCH_COMPANIES_FAILURE, SORT_COMPANIES_ALPHABETICALLY} from '../actions/companiesActions';
const initialState = {
    companies: [],
    loading: false,
    error: null,
  };
  
  const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMPANIES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_COMPANIES_SUCCESS:
        return {
          ...state,
          companies: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_COMPANIES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case SORT_COMPANIES_ALPHABETICALLY:
        return {
          ...state,
          companies: [...state.companies.sort((a, b) => a.name.localeCompare(b.name))],
        };
      default:
        return state;
    }
  };
  
  export default companiesReducer;