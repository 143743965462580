import {getModels} from '../../utils/api';

export const SORT_CARS_ALPHABETICALLY = 'SORT_CARS_ALPHABETICALLY';
export const FETCH_CARS_REQUEST = 'FETCH_CARS_REQUEST';
export const FETCH_CARS_SUCCESS = 'FETCH_CARS_SUCCESS';
export const FETCH_CARS_FAILURE = 'FETCH_CARS_FAILURE';

export const fetchCarsRequest = () => ({
  type: FETCH_CARS_REQUEST,
});

export const fetchCarsSuccess = (cars) => ({
  type: FETCH_CARS_SUCCESS,
  payload: cars,
});

export const fetchCarsFailure = (error) => ({
  type: FETCH_CARS_FAILURE,
  payload: error,
});

export const sortCarsAlphabetically = () => {
    return { type: SORT_CARS_ALPHABETICALLY };
  };

export const fetchCars = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchCarsRequest());
      const response = await getModels();
      if (response.status === 'OK') {
        dispatch(fetchCarsSuccess(response.result));
        dispatch(sortCarsAlphabetically());
        
      } else {
        dispatch(fetchCarsFailure('Failed to fetch companies'));
      }
    } catch (error) {
      dispatch(fetchCarsFailure(error.message));
    }
  };
};