import {getCatalog} from '../../utils/api';

export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const SORT_COMPANIES_ALPHABETICALLY = 'SORT_COMPANIES_ALPHABETICALLY';

export const fetchCompaniesRequest = () => ({
    type: FETCH_COMPANIES_REQUEST,
  });
  
  export const fetchCompaniesSuccess = (companies) => ({
    type: FETCH_COMPANIES_SUCCESS,
    payload: companies,
  });
  
  export const fetchCompaniesFailure = (error) => ({
    type: FETCH_COMPANIES_FAILURE,
    payload: error,
  });
  
  export const sortCompaniesAlphabetically = () => ({
    type: SORT_COMPANIES_ALPHABETICALLY,
  });


  export const fetchCompanies = () => {
    return async (dispatch) => {
      try {
        dispatch(fetchCompaniesRequest());
        const response = await getCatalog();
        if (response.status === 'OK') {
          dispatch(fetchCompaniesSuccess(response.result));
          dispatch(sortCompaniesAlphabetically());  
        } else {
          dispatch(fetchCompaniesFailure('Failed to fetch companies'));
        }
      } catch (error) {
        dispatch(fetchCompaniesFailure(error.message));
      }
    };
  };