import React, {useEffect, useState} from "react";
import styles from "./Footer.module.css";
import {getInfo} from "../../../utils/api";

export const Footer = () => {
  const [SocialsLinks, setSocialsLinks] = useState([
    { description: "Instagram", link: "#" },
    { description: "E-mail", link: "#" },
    { description: "Telegram", link: "#" },
  ]);
  useEffect(()=>{
      const getInfoT = async () => {
          const res = await getInfo();
          if(res.status === 'OK'){
              setSocialsLinks([
                  { description: "Instagram", link: 'https://instagram.com/'+res.result.link_instagram },
                  { description: "E-mail", link: 'mailto:'+res.result.link_email },
                  { description: "Telegram", link: 'https://t.me/'+res.result.link_telegram },
              ])
          }
      }
      getInfoT();
  },[])
  const SocialElems = (text, link, key) => {
    return (
      <a className={styles.socialLink} href={link} key={key} target={'_blank'}>
        {text}
      </a>
    );
  };

  const Contacts = () => {
    return (
      <>
          <div className={styles.block1}>
              <div className={styles.title}>КОНТАКТЫ</div>
              <div className={styles.title2}></div>
          </div>
          <div className={styles.block1}>
              <p className={styles.description}>
                  Если у вас есть вопросы - вы можете<br></br> задать их нам в наших
                  социальных сетях
              </p>
              <div></div>
          </div>
          <div className={styles.block2}>
              <div className={styles.socials}>
                  {SocialsLinks.map((NavElem) => (
                      <label className={styles.link} key={NavElem.description}>
                          <div className={styles.circle} />
                          {SocialElems(
                              NavElem.description,
                              NavElem.link,
                              NavElem.description
                          )}
                      </label>
                  ))}
              </div>
              <div className={styles.warning}>
                  <p className={styles.warningTite}>ВНИМАНИЕ!</p>
                  <p className={styles.warningText}>
                      Будьте осторожны, вокруг много мошенников.
                  </p>
                  <p className={styles.warningText}>
                      Перед оплатой обязательно ВИДЕОСВЯЗЬ с Никитой Мошенец лично!
                  </p>
              </div>
          </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Contacts />
    </div>
  );
};
